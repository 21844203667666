import './MainScreen.css'
const MainScreen = () => {
    return (

        <div id="MainScreen_Box">
            <div id="MainScreen">
                <h1 id="nametext">Andrew Sasmito</h1>
                <h3 id="Description">Welcome to my site!</h3>
            </div>
            
        </div>

    )
}

export default MainScreen