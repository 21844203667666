import React from 'react'
import Skills from './components/Skills.js'
import MainScreen from './components/MainScreen.js'
import './App.css'


function App() {
  return (
    <div className="App">
      <header className="App-header">
        
      </header>
      <MainScreen></MainScreen>

      <Skills></Skills>

    </div>
    
  );
}
export default App;
