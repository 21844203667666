import { useRef, useEffect } from 'react'
import './Skills.css'
const Skills = () => {
    //Fade in scroll intersection
  const skillRef = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if(entry.isIntersecting)
      {
          entry.target.classList.add("show");
      }
      else{
          entry.target.classList.remove("show");
      }
    })
    observer.observe(skillRef.current);
  }, [])

  return (
    <h1 id = "Skill" className = "hidden" ref={skillRef}>
        Skills
    </h1>
  )
}

export default Skills